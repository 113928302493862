@import "../../index";

label.hamburger-button {
  .menu {
    position: absolute;
    right: -100px;
    top: -100px;
    z-index: 2000;
    width: 200px;
    height: 200px;
    background: transparent;
    border-radius: 50% 50% 50% 50%;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  .hamburger {
    position: absolute;
    top: 135px;
    right: 135px;
    width: 30px;
    height: 2px;
    background: #000;
    display: block;
    transform-origin: center;
    transition: 0.3s ease-in-out;

    &:after,
    &:before {
      transition: 0.3s ease-in-out;
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #000;
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  }
  input {
    display: none;
  }
  input:checked + .menu {
    width: calc(100vw + 100px);
    border-radius: 0;

    .hamburger {
      transform: rotate(45deg);
      &:after {
        transform: rotate(90deg);
        bottom: 0;
      }
      &:before {
        transform: rotate(90deg);
        top: 0;
      }
    }
  }

  ul {
    font-size: 10vh;
    font-family: "Frunchy";
    z-index: 200;
    right: calc(-100vw - 100px);
    position: absolute;
    list-style-type: none;
    transition: 0.3s ease-in-out;
    background: $pale-green;
    width: calc(100vw - 40px);
    margin: 0;
    padding-top: 15vh;
    height: 100vh;
  }

  input:checked + .menu + ul {
    right: 0;
    transition: 0.7s ease-in-out;
  }

  .menu-link {
    margin-bottom: 0.5em;
    display: block;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 100ms;
    transition-property: letter-spacing;

    &:hover {
      letter-spacing: 1.5px;
    }
  }

  @media (min-width: 768px) {
    ul {
      width: 10ch;
      right: calc(-10ch - 170px);
    }

    input:checked + .menu {
      width: 40vw;
    }

    input:checked + .menu + ul {
      right: 0vw;
    }
  }
}
