#home {
  position: absolute;
  width: 100vw;

  .home-animation {
    height: 100vh;
    height: var(--app-height);
    position: relative;

    .slider {
      position: absolute;
      height: 100vh;
      height: var(--app-height);
      opacity: 1;
      overflow: hidden;
      transition: all 0.6s;
      text-align: center;
      margin: auto;
      line-height: 100vh;
      line-height: var(--app-height);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .slider.close {
      opacity: 0;
    }

    .dot-line {
      position: absolute;
      top: calc(50vh - 88px);
      top: calc(var(--app-height) / 2 - 88px);
      animation: grow 0.6s;
      -webkit-animation: grow 0.6s;

      &.I {
        animation-delay: 0.1s;
      }

      &.Y {
        animation-delay: 0.2s;
      }

      &.U {
        animation-delay: 0.3s;
      }
    }

    .dot-black {
      position: absolute;
      top: calc(50vh - 88px);
      top: calc(var(--app-height) / 2 - 88px);
      animation: appear 0.4s;
      -webkit-animation: appear 0.4s;
      opacity: 0;

      &.M {
        animation-delay: 0.6s;
      }

      &.I {
        animation-delay: 1s;
      }

      &.Y {
        animation-delay: 1.5s;
      }

      &.U {
        animation-delay: 1.9s;
      }

      &.stay {
        animation-delay: 2.3s;
        animation-fill-mode: forwards;
      }
    }

    @keyframes appear {
      to {
        opacity: 1;
      }
    }

    @keyframes grow {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1);
      }
    }

    .logo-appearing {
      width: 100vw;
      margin: auto;
      height: 100vh;
      height: var(--app-height);
      justify-content: center;

      .letter-M {
        width: 131px;

        img {
          width: 131px;
        }

        .slider {
          transition-delay: 0.6s;
        }
      }

      .letter-I {
        width: 45px;

        img {
          width: 45px;
        }

        .slider {
          transition-delay: 1s;
        }
      }

      .letter-Y {
        width: 81px;

        img {
          width: 81px;
        }

        .slider {
          transition-delay: 1.4s;
        }
      }

      .letter-U {
        width: 85px;

        img {
          width: 85px;
        }

        .slider {
          transition-delay: 1.8s;
        }
      }
    }

    .subtitle {
      width: 100vw;
      font-family: "Frunchy";
      position: absolute;
      top: calc(50% + 70px);
      font-size: 28px;
      opacity: 0;
      animation: appear 3s forwards;
      -webkit-animation: appear 3s forwards;
      animation-delay: 2.1s;
      text-align: center;
    }
  }

  @media (min-width: 2000px) {
    .home-animation {
      .dot-line {
        top: calc(50vh - 105px);
        top: calc(var(--app-height) / 2 - 105px);
      }

      .dot-black {
        top: calc(50vh - 105px);
        top: calc(var(--app-height) / 2 - 105px);
      }

      .logo-appearing {
        .letter-M {
          width: 157px;

          img {
            width: 157px;
          }
        }

        .letter-I {
          width: 54px;

          img {
            width: 54px;
          }
        }

        .letter-Y {
          width: 97px;

          img {
            width: 97px;
          }
        }

        .letter-U {
          width: 102px;

          img {
            width: 102px;
          }
        }
      }

      .subtitle {
        top: calc(50% + 84px);
        font-size: 36px;
      }
    }
  }

  @media (min-width: 2500px) {
    .home-animation {
      .dot-line {
        top: calc(50vh - 123px);
        top: calc(var(--app-height) / 2 - 123px);
      }

      .dot-black {
        top: calc(50vh - 123px);
        top: calc(var(--app-height) / 2 - 123px);
      }

      .logo-appearing {
        .letter-M {
          width: 183px;

          img {
            width: 183px;
          }
        }

        .letter-I {
          width: 63px;

          img {
            width: 63px;
          }
        }

        .letter-Y {
          width: 113px;

          img {
            width: 113px;
          }
        }

        .letter-U {
          width: 119px;

          img {
            width: 119px;
          }
        }
      }

      .subtitle {
        top: calc(50% + 98px);
        font-size: 44px;
      }
    }
  }

  @media (max-width: 374px) {
    .home-animation {
      .dot-line {
        top: calc(50vh - 70px);
        top: calc(var(--app-height) / 2 - 70px);
      }

      .dot-black {
        top: calc(50vh - 70px);
        top: calc(var(--app-height) / 2 - 70px);
      }

      .logo-appearing {
        .letter-M {
          width: 105px;

          img {
            width: 105px;
          }
        }

        .letter-I {
          width: 36px;

          img {
            width: 36px;
          }
        }

        .letter-Y {
          width: 65px;

          img {
            width: 65px;
          }
        }

        .letter-U {
          width: 68px;

          img {
            width: 68px;
          }
        }
      }

      .subtitle {
        top: calc(50% + 56px);
        font-size: 22px;
      }
    }
  }
}
