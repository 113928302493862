.fa-spinner {
  animation: spinner 1.5s infinite linear;
  color: white;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
