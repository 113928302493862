@import "../../index";

#about {
  position: absolute;
  width: 100vw;
  top: 90px;

  .title {
    font-family: "Frunchy";
    font-size: 14vw;
    margin-left: 5vw;
  }

  .intro-text {
    background-color: $pale-green;
    width: 80vw;
    bottom: 0;
    font-size: clamp(16px, 4vw, 22px);
    padding: 5vw;
    padding-bottom: 10vw;
    margin-left: 5vw;

    a {
      color: black;
    }
  }

  .our-faces {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-left: 5vw;
    width: 90vw;
    height: 90vw;
    position: relative;

    > * {
      aspect-ratio: 1;
    }

    .face-image-meg {
      grid-area: 1 / 1 / 3 / 4;
    }
    .meg-presentation {
      grid-area: 1 / 4 / 2 / 6;
      position: absolute;
      top: 5vh;
      margin-left: 2vw;
    }
    .face-image-irene {
      grid-area: 2 / 3 / 4 / 6;
    }
    .irene-presentation {
      grid-area: 3 / 2 / 4 / 4;
      position: absolute;
      top: 5vh;
      margin-left: -2vw;
    }

    .presentation {
      .name {
        font-size: 6vw;
        font-weight: 600;
      }

      .role {
        font-size: 4vw;
      }
    }

    .meg,
    .irene {
      width: calc(100% - 2vw);
      border-radius: 50%;
      border: 1px solid black;
      padding: 1vw;
    }

    .irene {
      margin-left: -2vw;
    }
  }

  @media (min-width: 768px) {
    height: calc(var(--app-height) - 90px);

    .title {
      line-height: 7vh;
      height: calc(100vh - 100px);
      position: absolute;
      grid-area: 1 / 1 / 5 / 2;
      font-family: "Frunchy";
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 22vh;
      text-align: center;
      width: fit-content;
    }

    .about-wrapper {
      display: flex;

      .intro-text {
        background-color: $pale-green;
        width: 32vw;
        position: absolute;
        bottom: 0;
        left: 15vw;
        font-size: 21px;
        padding: 2vw;
        margin-left: 0;
      }

      .our-faces {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        position: absolute;
        margin-left: 3vw;
        left: 50vw;
        width: 80vh;
        height: 80vh;
        max-width: 45vw;
        max-height: 50vw;
        top: 50%;
        transform: translate(0, -50%);

        > * {
          aspect-ratio: 1;
        }

        .meg-presentation {
          position: absolute;
          top: 5vh;
        }

        .irene-presentation {
          position: absolute;
          top: 5vh;
        }

        .presentation {
          .name {
            font-size: 24px;
            font-weight: 600;
          }

          .role {
            font-size: 18px;
          }
        }

        .meg,
        .irene {
          width: 100%;
          border-radius: 50%;
          border: 1px solid black;
          padding: 1vw;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .about-wrapper {
      .intro-text {
        font-size: calc(10px + 2vh);
        min-width: 340px;
      }
    }
  }

  @media (min-width: 1394px) {
    .about-wrapper {
      .intro-text {
        height: 70vh;
      }
    }
  }
}
