#header {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;

  .letters {
    padding: 10px 5vw;
  }

  .header-logo {
    text-align: center;
    padding: 1px;
    cursor: pointer;

    > :first-child {
      width: 5px;
      height: 5px;
    }

    > :last-child img {
      height: 30px;
      font-family: "Frunchy";
    }
  }

  @media (min-width: 768px) {
    height: 100px;
    background: transparent;

    .letters {
      padding: 10px 30px;
    }
  }
}
