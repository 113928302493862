@import "../../index";

.project-detail-large {
  position: fixed;
  bottom: 0;
  background: $pale-green;
  z-index: 5000;
  width: calc(100vw - 6vw);
  height: calc(100vh - 16vh);
  height: calc(var(--app-height) - 16vh);
  padding: 8vh 3vw;
  overflow: scroll;
  font-size: 18px;

  .project-title {
    font-size: 22px;
    font-weight: 500;
  }

  .close-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 70px;
    height: 70px;

    .close {
      position: absolute;
      top: 35px;
      right: 25px;
      right: 20px;
      width: 30px;
      height: 2px;
      background: #000;
      display: block;
      transform-origin: center;
      transform: rotate(45deg);

      &:before {
        transition: 0.3s ease-in-out;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
      }
      &:before {
        transform: rotate(90deg);
        top: 0;
      }
    }
  }

  .description {
    > * {
      margin-bottom: 20px;
    }

    .actions {
      div {
        margin-bottom: 10px;
      }
    }
    .link {
      margin-bottom: 50px;
      a {
        color: black;
      }
    }
  }

  .slick-dots li {
    width: 35px;
  }

  .slick-dots li button:before {
    background-color: #fff;
    border: 1.5px solid black;
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background: none;
    opacity: 1;
  }

  .slick-active::before {
    width: 10px;
    height: 10px;
  }

  .slick-dots li.slick-active button:before {
    background-color: black;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slider {
    padding-bottom: 30px;
    width: 94vw;
  }

  .slick-slide {
    img {
      width: 100%;
      margin: auto;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    height: calc(100vh - 8vw);
    padding: 4vw 3vw;

    > * {
      width: 45%;
    }

    .slick-slider {
      width: unset;
    }

    .slick-dots li button:before {
      width: 14px;
      height: 14px;
    }

    .project-title {
      font-size: 24px;
    }

    .description {
      .link {
        margin-bottom: unset;
      }
    }

    .slick-slide {
      height: 60vh;
    }
  }
}
