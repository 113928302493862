.app {
  position: relative;
  min-height: 100vh;
  min-height: var(--app-height);
}

.footer {
  display: none;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%);
  width: 130px;
  justify-content: space-between;

  > * {
    cursor: pointer;
    width: 15px !important;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}
