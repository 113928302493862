$black: #272727;
$pale-green: #f5f6f4;

:root {
  --app-height: 100%;
}

body {
  margin: 0;
  font-family: "Bellota Text", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  box-sizing: border-box;
  font-weight: 300;

  > * {
    -webkit-tap-highlight-color: transparent;
  }

  strong {
    font-weight: bold;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Frunchy";
  font-display: swap;
  src: local("Frunchy"), url("./assets/fonts/Frunchy.woff2") format("woff2"),
    url("./assets/fonts/Frunchy.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&display=swap");

@font-face {
  font-family: "Bellota Text";
  font-display: swap;
  font-style: light;
}

@font-face {
  font-family: "Bellota Text";
  font-display: swap;
  font-style: bold;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }
}

.hidden {
  display: none;
}
