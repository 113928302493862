@import "../../index";

#contact {
  position: absolute;
  width: 100vw;
  top: 90px;

  .title {
    font-family: "Frunchy";
    font-size: 14vw;
    text-align: left;
    margin-left: 5vw;
  }

  .contact-form {
    position: relative;
    padding: 0 20px;

    .subtitle {
      font-size: 20px;
      margin-bottom: 20px;

      .width-50 {
        width: 50%;
      }

      :last-child {
        margin-left: 5px;
      }

      input {
        background-color: transparent;
        line-height: 100%;
      }

      ::placeholder {
        color: black;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: black;
      }

      ::-ms-input-placeholder {
        color: black;
      }

      span {
        margin-left: 0 !important;
      }
    }

    .input-container {
      padding-left: 10px;
      background-color: $pale-green;
    }

    .input-fields {
      > * {
        height: 45px;
        margin-bottom: 15px;
      }
    }

    input,
    textarea {
      border: none;
      width: 100%;
      height: 100%;
      background-color: $pale-green;
      font: inherit;
      font-size: 20px;
      resize: none;
      padding: 0;
      line-height: 42px;

      &:focus {
        outline: none;
      }
    }

    .comment {
      background-color: $pale-green;
    }

    .submit-button {
      width: 200px;
      margin: 20px auto;
      transition: all 0.1s;

      &:hover {
        letter-spacing: 1.3px;
      }
    }
  }

  @media (min-width: 768px) {
    .title {
      height: calc(100vh - 100px);
      position: absolute;
      grid-area: 1 / 1 / 5 / 2;
      font-family: "Frunchy";
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 22vh;
      text-align: center;
      width: fit-content;
      line-height: 7vh;
    }

    .contact-form {
      padding: 0;
      position: relative;
      display: grid;
      grid-template-columns: 2fr 4fr 4fr 2fr;
      grid-template-rows: 2fr 3fr 3fr 3fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;

      .subtitle {
        grid-area: 1 / 2 / 1 / 4;
        font-size: 20px;
        align-items: flex-end;
      }

      .input-container {
        padding-left: 10px;
        background-color: $pale-green;
      }

      .input-fields {
        grid-area: 2 / 2 / 4;

        > * {
          height: 45px;
          margin-bottom: 15px;
        }
      }

      input,
      textarea {
        border: none;
        width: 100%;
        height: 100%;
        background-color: $pale-green;
        font: inherit;
        font-size: 20px;
        resize: none;
        padding: 0;
        line-height: 42px;

        &:focus {
          outline: none;
        }
      }

      .comment {
        grid-area: 2 / 3 / 4;
        background-color: $pale-green;
      }
      .submit-button {
        grid-area: 4 / 2 / 4 / 4;
      }
    }
  }
}
