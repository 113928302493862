@import "../../index";

#work {
  position: absolute;
  width: 100vw;
  top: 90px;

  .title {
    font-family: "Frunchy";
    font-size: 14vw;
    margin-left: 5vw;
    line-height: 7vh;
  }

  .flex.column {
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    font-size: 12vw;

    > * {
      cursor: pointer;
    }

    :nth-child(odd) * {
      font-family: "Frunchy";
    }

    :nth-child(even) * {
      font-family: "Bellota Text";
    }

    &.render-project {
      z-index: 0;
      color: black;
      position: relative;

      .project-detail {
        &:hover {
          color: #dbdfd7;
          opacity: 0.8;

          > * {
            opacity: 1;
            visibility: visible;
          }
        }
        .project-title {
          position: relative;
          z-index: 4;
        }
      }
    }

    img {
      position: fixed;
      opacity: 0;
      visibility: hidden;
      transition: all 0.8s ease;
      z-index: -20;
    }
  }

  .motion-background {
    position: absolute;
    z-index: 2;
  }

  @media (min-width: 450px) {
    .flex.column {
      font-size: 9vw;
    }
  }

  @media (min-width: 768px) {
    .title {
      height: calc(100vh - 100px);
      position: absolute;
      grid-area: 1 / 1 / 5 / 2;
      font-family: "Frunchy";
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 22vh;
      text-align: center;
      width: fit-content;
    }

    .flex.column {
      font-size: 6vw;
    }
  }

  @media (min-width: 1200px) {
    .flex.column {
      font-size: 4vw;
    }
  }
}
