@import '../../index';

.submit-button {
  color: white;
  background-color: $black;
  width: 30%;
  height: 47px;
  margin: auto;
  text-align: center;
  font-size: 24px;
  line-height: 47px;
  font-family: inherit;
  border: none;
  cursor: pointer;

  &.sent {
    background-color: gray;
    cursor: auto;
  }
}
